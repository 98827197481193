<template>
  <div class="zsonm">
    <div class="zsonm__header">
      Качество жизни у пациентов с ЗСОНМ ухудшается<sup>21</sup>.
    </div>
    <div class="zsonm__subheader">
      Процент пациентов с ЗСОНМ, сообщающих о симптоматике умеренной или высокой
      тяжести (n=552)21*
    </div>
    <VueSlickCarousel
      class="zsonm__slider"
      ref="carouselSlider"
      v-bind="settings"
    >
      <div v-for="(slide, index) in slides" :key="index" class="zsonm__slide">
        <div class="zsonm__icon">
          <img :src="slide.icon" alt="slide" />
        </div>
      </div>
    </VueSlickCarousel>
    <div class="zsonm__footer">
      <div class="zsonm__text">
        * Ретроспективное обсервационное исследование на 522 пациентах с ЗСОНМ
        из онлайн-сообщества PatientsLikeMe (PLM)
      </div>
      <div class="zsonm__footer-nav">
        <div
          class="zsonm__arrow zsonm__arrow--left"
          :class="{ 'zsonm__arrow--inactive': currentSlide === 0 }"
          @click="prevSlide"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.5L20.25 12L12.75 19.5"
              :stroke="currentSlide === 0 ? '#8B8E8E' : '#830051'"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12L3.75 12"
              :stroke="currentSlide === 0 ? '#8B8E8E' : '#830051'"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div
          class="zsonm__arrow zsonm__arrow--right"
          :class="{ 'zsonm__arrow--inactive': currentSlide === maxSlideIndex }"
          @click="nextSlide"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.5L20.25 12L12.75 19.5"
              :stroke="currentSlide === maxSlideIndex ? '#8B8E8E' : '#830051'"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12L3.75 12"
              :stroke="currentSlide === maxSlideIndex ? '#8B8E8E' : '#830051'"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "zsonmSlider",
  components: {
    VueSlickCarousel,
  },
  data: () => ({
    currentSlide: 0,
    settings: {
      arrows: false,
      dots: false,
      draggable: false,
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    },
  }),
  computed: {
    slides() {
      return [
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/1.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/2.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/3.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/4.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/5.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/6.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/7.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/8.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/9.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/10.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/11.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/12.png`,
        },
        {
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/zsonm/slides/13.png`,
        },
      ];
    },
    maxSlideIndex() {
      return this.slides.length - this.settings.slidesToShow;
    },
  },
  methods: {
    nextSlide() {
      if (this.currentSlide < this.maxSlideIndex) {
        this.$refs.carouselSlider.next();
        this.updateCurrentSlide(1);
      }
    },
    prevSlide() {
      if (this.currentSlide > 0) {
        this.$refs.carouselSlider.prev();
        this.updateCurrentSlide(-1);
      }
    },
    updateCurrentSlide(step) {
      this.currentSlide += step;
    },
  },
  watch: {
    currentSlide(val) {
      const slidesToShow =
        window.innerWidth < 768 ? 2 : window.innerWidth < 1199 ? 3 : 5;
      this.maxSlideIndex = this.slides.length - slidesToShow;
    },
  },
};
</script>

<style lang="scss">
.zsonm {
  border: 1px solid #0d468f;
  &__header {
    padding: 16px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    background-color: #0d468f;
    color: #fff;
  }

  &__subheader {
    padding: 24px 24px 0 24px;
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #3c4242;
  }

  &__slider {
    position: relative;
  }

  &__slide {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1em;
    box-sizing: border-box;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 130px;
    max-height: 154px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__footer {
    padding: 0 24px 24px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    &-nav {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  }
  &__arrow {
    cursor: pointer;
    background: #ebefee;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 999px;
    &--left {
      transform: rotate(180deg);
    }
    &--inactive {
      background-color: #d8dada;
      svg {
        path {
          stroke: #8b8e8e;
        }
      }
    }
  }
}
</style>
