<template>
  <div class="nf1__m">
    <div class="nf1__m-grid">
      <component
        :is="item.link ? 'a' : 'div'"
        class="nf1__m-grid_item"
        v-for="(item, index) in items"
        :key="index"
        @click="!item.link && openModal(index)"
        v-bind="item.link ? { href: item.link, target: '_blank' } : {}"
      >
        <div class="nf1__m-grid_item-text">
          <div class="nf1__m-grid_item-title" v-html="item.title"></div>
          <!-- <div class="nf1__m-grid_item-descr">{{ item.description }}</div> -->
        </div>
        <div class="nf1__m-grid_item-svg">
          <div class="nf1__m-grid_item-svg_container">
            <div>Подробнее</div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 3.00012L11 8.00012L6 13.0001"
                stroke="#830051"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </component>
    </div>
    <div
      v-if="selectedItemIndex !== null"
      class="modal-overlay"
      @click.self="closeModal"
    >
      <div class="modal">
        <div class="modal-header">
          <div
            class="modal-header_title"
            v-html="modalData[selectedItemIndex].title"
          ></div>
          <div class="close" @click="closeModal">
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 7L25 25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M25 7L7 25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-block">
            <div
              class="modal-block-text"
              v-for="(block, index) in modalData[selectedItemIndex].content"
              :key="index"
            >
              <div class="modal-block-text__inner" v-html="block.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NF1ModalBlock",
  data() {
    return {
      items: [
        {
          title:
            "<div class='mb-4'>6 и более <span style='font-weight: 500;'>гиперпигментированных пятен</span> (пятна «кофе с молоком»)*:</div><ul style='padding-left: 30px'><li>в препубертатном периоде – размером 5 мм и более;</li><li>в постпубертатном периоде – размером 15 мм и более.</li></ul>",
        },
        {
          title:
            "<div>2 и более <span style='font-weight: 500;'>узелков Лиша</span> на радужной оболочке (гамартомы радужки), выявленных при осмотре с помощью щелевой лампы, или 2 и более аномалии сосудистой оболочки глаза (хороидальные гамартомы), определяемые как высокорефлективные узелки (включения) на ОКТ (оптическая когерентная томография) или при проведении лазерной сканирующей офтальмоскопии</div>",
        },
        {
          title:
            "<div><span style='font-weight: 500;'>Глиома зрительного пути</span></div>",
        },
        {
          title:
            "<div>2 или более <span style='font-weight: 500;'>нейрофибром</span> любого типа или 1 <span style='font-weight: 500;'>плексиформная нейрофиброма</span>**</span></div>",
        },
        {
          title:
            "<div>Множественные, похожие на <span style='font-weight: 500;'>веснушки</span>, гиперпигментированные пятна в подмышечной или паховой областях*</div>",
        },
        {
          title:
            "<div>Характерные <span style='font-weight: 500;'>изменения костей</span> (такие, как дисплазия крыла клиновидной кости**, дисплазия / или дугообразная деформация / или ложные суставы длинных трубчатых костей)</div>",
        },
      ],
      
      selectedItemIndex: null,
    };
  },
  computed: {
    modalData() {
      return [
        {
          title: "Пятна цвета «кофе с молоком»<sup>6-8,12,13</sup>",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/1-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/1-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/1.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/1.png'/></picture>`,
            },
          ],
        },
        {
          title: "Пятна, похожие на веснушки, в подмышечной / паховой областях<sup>1-3,7,8</sup>",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/2-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/2-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/2.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/2.png'/></picture>`,
            },
          ],
        },
        {
          title: "Гамартомы радужки (узелки Лиша)<sup>1-3,7,8</sup>",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/3-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/3-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/3.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/3.png'/></picture>`,
            },
          ],
        },
        {
          title: "Глиома зрительного пути<sup>1-3,7,8,10</sup>",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/4-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/4-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/4.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/4.png'/></picture>`,
            },
          ],
        },
        {
          title: "Характерные изменения костей<sup>1-3,7,8</sup>",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/5-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/5-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/5.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/5.png'/></picture>`,
            },
          ],
        },
        {
          title: "Нейрофибромы при НФ1<sup>1,7</sup>",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/6-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/6-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/6.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/6.png'/></picture>`,
            },
          ],
        },
      ]
    },
  },
  methods: {
    openModal(index) {
      this.selectedItemIndex = index;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.selectedItemIndex = null;
      document.body.style.overflow = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.nf1__m {
  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
    &_item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      background-color: #f3f7cc;
      transition: all 0.2s linear;
      border-radius: 16px;
      &:hover {
        background-color: #f3f7cc8a;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: space-between;
      }
      &-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      &-title {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &-descr {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
      &-svg {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &_container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 8px;
          gap: 8px;
          border: 1px solid #830051;
          border-radius: 999px;
          max-width: fit-content;
          color: #830051;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }
  }
}

.modal {
  background: #f8f8f8;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  @media screen and (max-width: 1199px) {
    width: 80%;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    overflow: hidden;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9A3374;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    &_title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #ffffff;
    }
  }
  .close {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  &-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 80vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-block {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-bottom: 24px;
    &-text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__inner {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
</style>
