<template>
  <div class="breztri-other">
    <div class="breztri-other__container mb-12">
      <div class="breztri-other__tabs">
        <div
          class="breztri-other__tabs-btn"
          @click="changeTab(0)"
          :class="{ active: activeTab === 0 }"
        >
          <div class="breztri-other__tabs-btn_wrap">
            Дозированный порошковый ингалятор (ДПИ)
          </div>
        </div>
        <div
          class="breztri-other__tabs-btn"
          @click="changeTab(1)"
          :class="{ active: activeTab === 1 }"
        >
          <div class="breztri-other__tabs-btn_wrap">
            Дозированный аэрозольный ингалятор (ДАИ)
          </div>
        </div>
        <div
          class="breztri-other__tabs-btn"
          @click="changeTab(2)"
          :class="{ active: activeTab === 2 }"
        >
          <div class="breztri-other__tabs-btn_wrap">Брезтри Аэросфера</div>
        </div>
      </div>
      <div class="breztri-other__content" :style="innerBorderStyle">
        <div class="breztri-other__content-block" v-show="activeTab === 0">
          <div class="breztri-other__text">
            Порошковый ингалятор представляют собой устройство, в котором
            препарат находится в виде смеси частиц активного вещества с инертным
            носителем — например, лактозой. Препарат из ДПИ выделяется пассивно,
            благодаря энергии, создаваемой вдохом пациента. Кроме того,
            достаточный поток воздуха на вдохе должен отделять частицы носителя
            от частиц активного компонента. Для пациентов с ХОБЛ сделать вдох с
            усилием зачастую трудно. Вдох без усилия приводит к тому, что часть
            препарата остается в ингаляторе, а часть, попавшая в дыхательные
            пути, не может освободиться от частиц носителя и оказать лечебный
            эффект<sup>6</sup>.
          </div>
          <div class="breztri-other__img">
            <picture>
              <source
                :srcset="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_1(mob).png
                `"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_1(tab).png
                `"
                media="(max-width: 1199px)"
              />
              <source
                :srcset="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_1.png
                `"
                media="(min-width: 1299px)"
              />
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_1.png`"
              />
            </picture>
          </div>
        </div>
        <div class="breztri-other__content-block" v-show="activeTab === 1">
          <div class="breztri-other__text">
            Дозированные аэрозольные ингаляторы выделяют препарат активно,
            частицы действующего вещества уже имеют начальную скорость движения.
            Чтобы доставка лекарства была максимальной, пациент должен
            активировать ингалятор в начале вдоха, медленно без усилия вдохнуть
            и задержать дыхание после ингаляции. Пациенты с ХОБЛ — это в
            основном люди пожилого возраста, у них бывают проблемы с
            координацией вдоха и ошибки при использовании ДАИ. Кроме того,
            препарат обычно находится в виде суспензии, которая может
            образовывать осадок и влиять на доставляемую дозу. Чтобы избежать
            этого, ингалятор перед применением нужно встряхивать<sup>6</sup>.
          </div>
          <div class="breztri-other__img">
            <picture>
              <source
                :srcset="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_2(mob).png
                `"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_2(tab).png
                `"
                media="(max-width: 1199px)"
              />
              <source
                :srcset="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_2.png
                `"
                media="(min-width: 1299px)"
              />
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_2.png`"
              />
            </picture>
          </div>
        </div>
        <div
          class="breztri-other__content-block third-block"
          v-show="activeTab === 2"
        >
          <div class="breztri-other__text">
            Ингалятор Аэросфера имеет технологию ко-суспензионной доставки:
            сочетает кристаллы лекарственного средства с пористыми частицами
            фосфолипидов для создания стабильной, однородной суспензии. Пористые
            частицы имеют структуру пены, а поскольку фосфолипиды — это
            естественный компонент легочного сурфактанта, они растворяются
            сразу, как только попадают в дыхательные пути. Это дает
            возможность<sup>7</sup>:
            <div class="breztri-other__text-container">
              <ul class="breztri-other__text-list">
                <li>
                  одновременно доставить сразу несколько лекарственных средств
                  из одного и того же устройства;
                </li>
                <li>повысить однородность суспензии;</li>
                <li>
                  отказаться от введения в состав вспомогательных компонентов:
                  поверхностно-активных веществ, растворителей;
                </li>
                <li>
                  создать стабильную фракцию мелкодисперсных частиц и обеспечить
                  их оптимальную доставку в центральные и периферические
                  дыхательные пути.
                </li>
              </ul>
              <div class="breztri-other__text-container_img">
                <picture>
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_3(mob).png
                    `"
                    media="(max-width: 767px)"
                  />
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_3(tab).png
                    `"
                    media="(max-width: 1199px)"
                  />
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_3.png
                    `"
                    media="(min-width: 1299px)"
                  />
                  <img
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_3.png`"
                  />
                </picture>
              </div>
            </div>
            Исследование депозиции в легких при применении препарата БРЕЗТРИ
            Аэросфера, проведенное у здоровых добровольцев, показало, что в
            среднем 38% номинальной дозы достигает легких после применения
            препарата с задержкой дыхания на 10 секунд. Соответствующее значение
            после применения препарата с задержкой дыхания на 3 секунды
            составило 35%<sup>1</sup>.
          </div>
          <div class="breztri-other__img third-img">
            <picture>
              <source
                :srcset="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_3.png
                `"
                media="(min-width: 1299px)"
              />
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/breztry/img/tab-img_3.png`"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreztriTabs",
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    innerBorderStyle() {
      if (this.activeTab === 0) {
        return {
          "border-radius": "0px 8px 8px 8px",
        };
      } else if (this.activeTab === 2) {
        return {
          "border-radius": "8px 0px 8px 8px",
        };
      } else {
        return {
          "border-radius": "8px",
        };
      }
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
    scrollToTabTopAndChangeTab(index) {
      this.changeTab(index);
      if (
        (this.user && Object.keys(this.user).length) ||
        this.$route.query.access
      ) {
        setTimeout(() => {
          const tabBtn = document.querySelector(
            `.breztri-other__tabs-btn:nth-child(${index + 1})`
          );
          if (tabBtn) {
            const scrollHeight = this.getScrollHeight(); // Получить высоту прокрутки в зависимости от ширины экрана
            const topPosition =
              tabBtn.getBoundingClientRect().top +
              window.pageYOffset -
              scrollHeight;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
          }
        }, 100); // Задержка для обеспечения корректной смены таба перед прокруткой
      }
    },
    getScrollHeight() {
      if (window.innerWidth >= 1200) {
        return 150; // Прокрутка для широкого экрана
      } else if (window.innerWidth >= 768) {
        return 30; // Прокрутка для узкого экрана
      } else {
        return 10;
      }
    },
  },
};
</script>

<style lang="scss">
.breztri-other {
  &__tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-height: 108px;
    @media screen and(max-width: 1199px) {
    }
    @media screen and(max-width: 767px) {
      max-height: 120px;
      gap: 8px;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      cursor: pointer;
      padding: 16px;
      width: 100%;
      height: 72px;
      background-color: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
      &_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and(max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &.active {
        border: none;
        height: 108px;
        border-radius: 8px 8px 0px 0px;
        background-color: #fceecc;
        @media screen and(max-width: 767px) {
          height: 96px;
        }
      }
      @media screen and(max-width: 1199px) {
        max-width: 200px;
        font-size: 14px;
        padding: 16px;
        height: 92px;
      }
      @media screen and(max-width: 767px) {
        height: 80px;
        font-size: 12px;
        line-height: 16px;
        padding: 8px;
        font-weight: 500;
      }
    }
  }
  &__content {
    padding: 24px;
    background-color: #fceecc;
    @media screen and(max-width: 1199px) {
      padding: 24px 16px;
    }
    &-block {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 32px;
      align-items: center;
      @media screen and(max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
    .third-block {
      @media screen and(max-width: 1199px) {
        grid-template-columns: 1fr;
      }
    }
  }
  &__text {
    &-container {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      @media screen and(max-width: 1199px) {
        margin: 16px 0;
      }
      @media screen and(max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
      }
      &_img {
        display: none;
        @media screen and(max-width: 1199px) {
          display: block;
          height: 320px;
        }
        @media screen and(max-width: 767px) {
          height: auto;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-list {
      margin: 16px 0;
      @media screen and(max-width: 1199px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 320px;
        margin: 0;
      }
      @media screen and(max-width: 767px) {
        min-height: auto;
      }
    }
  }
  &__img.third-img {
    @media screen and(max-width: 1199px) {
      display: none;
    }
  }
}
</style>
