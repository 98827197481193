<template>
  <div class="nf1__m">
    <div class="nf1__m-grid">
      <component
        :is="item.link ? 'a' : 'div'"
        class="nf1__m-grid_item"
        v-for="(item, index) in items"
        :key="index"
        @click="!item.link && openModal(index)"
        v-bind="item.link ? { href: item.link, target: '_blank' } : {}"
      >
        <div class="nf1__m-grid_item-text">
          <div class="nf1__m-grid_item-title" v-html="item.title"></div>
        </div>
        <div class="nf1__m-grid_item-svg">
          <div class="nf1__m-grid_item-svg_container">
            <div>Смотреть</div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 3.00012L11 8.00012L6 13.0001"
                stroke="#830051"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </component>
    </div>

    <div
      v-if="selectedItemIndex !== null"
      class="modal-overlay"
      @click.self="closeModal"
    >
      <div class="modal">
        <div class="modal-header">
          <div
            class="modal-header_title"
            v-html="modalData[selectedItemIndex].title"
          ></div>
          <div class="close" @click="closeModal">
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 7L25 25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M25 7L7 25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
        <div class="modal-content modal-content__two">
          <!-- Условие: Если isSlider === "true" -->
          <div
            v-if="modalData[selectedItemIndex].isSlider === 'true'"
            class="slider"
          >
            <div
              class="slider-container"
              :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
            >
              <div
                class="slide"
                v-for="(block, index) in modalData[selectedItemIndex].content"
                :key="index"
                v-html="block.text"
              ></div>
            </div>
            <div class="slider-carousel__nav-row">
              <div class="slider-carousel__counter">
                {{ currentSlide + 1 }}/{{
                  modalData[selectedItemIndex].content.length
                }}
              </div>
              <div class="slider-carousel__arrows">
                <div
                  class="slider-carousel__arrow slider-carousel__arrow_prev"
                  @click="prevSlide"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.49691e-07 8L7 16L9.5 16L3.5 9L16 9L16 7L3.5 7L9.5 -2.84124e-07L7 -3.93402e-07L3.49691e-07 8Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div
                  class="slider-carousel__arrow slider-carousel__arrow_next"
                  @click="nextSlide"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <!-- Условие: Если isSlider !== "true" -->
          <div v-else>
            <div
              class="modal-block-text__inner"
              v-for="(block, index) in modalData[selectedItemIndex].content"
              :key="index"
              v-html="block.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NF1ModalBlock2",
  data() {
    return {
      items: [
        {
          title:
            "<span style='font-weight: 500;'>ПН запястья на МРТ (Т2 взвешенные изображения с подавлением жира)<sup>28</sup></span>",
        },
        {
          title:
            "<span style='font-weight: 500;'>МРТ-исследование мягких тканей бедер – нижние конечности</span>",
        },
        {
          title:
            "<span style='font-weight: 500;'>МРТ-исследование мягких тканей головы и шеи</span>",
        },
        {
          title:
            "<span style='font-weight: 500;'>МРТ-исследование орбиты. ПН периорбитальной области</span>",
        },
        {
          title:
            "<span style='font-weight: 500;'>МРТ-исследование брюшной полости и малого таза</span>",
        },
      ],

      selectedItemIndex: null,
      currentSlide: 0,
    };
  },
  computed: {
    modalData() {
      return [
        {
          title:
            "ПН запястья на МРТ (Т2 взвешенные изображения с подавлением жира)<sup>32</sup>:",
          isSlider: "false",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/7-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/7-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/7.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/7.png'/></picture>`,
            },
          ],
        },
        {
          title: "МРТ-исследование мягких тканей головы и шеи",
          isSlider: "true", // Указывает, что нужно показывать слайдер
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3.png'/></picture>`,
            },
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3-1-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3-1-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3-1.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/3-1.png'/></picture>`,
            },
          ],
        },
        {
          title: "МРТ-исследование брюшной полости и малого таза",
          isSlider: "true", // Указывает, что нужно показывать слайдер
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4.png'/></picture>`,
            },
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-1-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-1-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-1.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-1.png'/></picture>`,
            },
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-2-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-2-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-2.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/4-2.png'/></picture>`,
            },
          ],
        },
        {
          title: "МРТ-исследование мягких тканей бедер – нижние конечности",
          isSlider: "false",
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/8-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/8-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/8.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/popups/8.png'/></picture>`,
            },
          ],
        },
        {
          title: "МРТ-исследование орбиты. ПН периорбитальной области",
          isSlider: "true", // Указывает, что нужно показывать слайдер
          content: [
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5.png'/></picture>`,
            },
            {
              text: `<picture><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5-1-mob.png' media='(max-width: 767px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5-1-tab.png' media='(max-width: 1199px)'/><source srcset='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5-1.png' media='(min-width: 1299px)'/><img src='https://storage.yandexcloud.net/${this.$root.backetName}/media/content/nf1/mslider/5-1.png'/></picture>`,
            },
          ],
        },
      ];
    },
  },
  methods: {
    openModal(index) {
      this.selectedItemIndex = index;
      this.currentSlide = 0; // Сбрасываем слайдер на первый слайд
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.selectedItemIndex = null;
      document.body.style.overflow = "";
    },
    nextSlide() {
      if (
        this.currentSlide <
        this.modalData[this.selectedItemIndex].content.length - 1
      ) {
        this.currentSlide++;
      }
    },
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nf1__m {
  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
    &_item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      background-color: #f3f7cc;
      transition: all 0.2s linear;
      border-radius: 16px;
      &:last-child {
        grid-area: 3 / 1 / 4 / 3;
        @media screen and (max-width: 1199px) {
          grid-area: auto;
        }
      }
      &:hover {
        background-color: #f3f7cc8a;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        min-height: auto;
        gap: 16px;
      }
      &-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      &-title {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &-descr {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
      &-svg {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &_container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 12px;
          gap: 8px;
          border: 1px solid #830051;
          border-radius: 999px;
          max-width: fit-content;
          color: #830051;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }
  }
}

.modal {
  background: #f8f8f8;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  @media screen and (max-width: 1199px) {
    width: 80%;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    overflow: hidden;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9a3374;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    gap: 24px;
    &_title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #ffffff;
    }
  }
  .close {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  &-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 80vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-block {
    display: flex;
    flex-direction: row;
    gap: 16px;
    &-text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__inner {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  .slide {
    flex: 0 0 100%;
    box-sizing: border-box;
  }
  &-container {
    display: flex;
    transition: transform 0.3s ease;
  }
  &-carousel {
    &__nav-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__counter {
      margin-right: 25px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #830051;
    }
    &__arrows {
      display: flex;
      &:first-child {
        margin-right: 18px;
      }
    }
    &__arrow {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #ebefee;
      color: inherit;
      cursor: pointer;
      transition: 0.3s;
      & svg path {
        fill: #830051;
      }
    }
  }
}
</style>
